<template>
  <div class="w3-modal w3-animate-opacity modal_in_modal" style="display:block">
    <div class="w3-modal-content w3-card-4 w3-animate-zoom pt20 pb30 pr20 pl25">
      <div class="w3-center">
        <span @click="$emit('close', false)" class="w3-button w3-xlarge w3-transparent w3-display-topright fs30 bold lh25" title="Close Modal">×</span>
      </div>
      <div class="w3-section">
        <div v-if="cardList.length === 0">

        </div>
        <div v-if="cardList.length > 0">
          <table class="table03">
            <colgroup>
              <col>
              <col style="width: 60%">
              <col style="width: 10%">
            </colgroup>
            <tbody>
            <tr v-for="(item, index) in cardList" :key="index">
              <td ><img :src='item.cardImg' style="width: 100%"></td>
              <td class="tx_cnt">{{item.cardNo}} | {{item.cardNm}}</td>
              <td>
                <button @click="$emit('selectItem', item)" class="btnwrap btn btn-default pt5 pb4 btn-sm">선택</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CardSelect",
  props:[
    'authId','authPwd', 'authIdx', 'cardNo'
  ],
  data() {
    return {
      cardList: []
    };
  },
  mounted(){
    this.fetchData();
  },
  methods: {
    fetchData() {
      // 등록된 인증정보의 idx 혹은 사이트 id/pwd 를 통해 롯데카드사에서 카드목록조회
      var params = {
        idx: this.authIdx,
        authId: this.authId,
        password: this.authPwd,
        financeInfo: {financeNo : this.cardNo}
      }

      // 인증정보를 이용해 카드사에서 등록된 카드목록을 가져온다.
      // id&password 혹은 이미 등록된 인증정보의 idx 를 가지고 조회한다.
      this.$cAxios.post('/api/auth/card/list/lotte', params,{
        headers:{
          'Content-Type' : 'application/json'
        }
      }).then(res =>{
        this.cardList = res.data
      }).catch(err => {
        alert(err.response.data.message);
        this.$emit('close', false);
      });
    }
  },
};
</script>

<style>
  .modal_in_modal{
    z-index: 4 !important;
  }
</style>